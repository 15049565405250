.article-teaser-list {
}

.article-teaser-list__title {
	margin-block-end: 0;

	/* from-medium,tablet-l,min-width-1024px */
	@media (width >= 64rem) {
		margin-inline-start: calc(((100% - (11 * var(--gutter))) / (12 / 3)) + (3 * var(--gutter)));
	}

	/* from-large,min-width-1440px */
	@media (width >= 90rem) {
		margin-inline-end: var(--space-120--px);
	}
}
